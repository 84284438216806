import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
import { PATH_AFTER_LOGIN } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
   // eslint-disable-next-line react-hooks/rules-of-hooks
   const { pathname } = useLocation();

   return (
      <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
         <Component {...props} />
      </Suspense>
   );
};

export default function Router() {
   return useRoutes([
      {
         path: 'auth',
         children: [
            {
               path: 'login',
               element: (
                  <GuestGuard>
                     <Login />
                  </GuestGuard>
               ),
            },
            // {
            //    path: 'register',
            //    element: (
            //       <GuestGuard>
            //          <Register />
            //       </GuestGuard>
            //    ),
            // },
            { path: 'login-unprotected', element: <Login /> },
            { path: 'register-unprotected', element: <Register /> },
            // { path: 'reset-password', element: <ResetPassword /> },
            // { path: 'new-password', element: <NewPassword /> },
            // { path: 'verify', element: <VerifyCode /> },
         ],
      },

      // Dashboard Routes
      {
         path: 'dashboard',
         element: (
            <AuthGuard>
               <DashboardLayout />
            </AuthGuard>
         ),
         children: [
            { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
            { path: 'app', element: <GeneralApp /> },
            // // { path: 'app', element: <GeneralApp /> },
            // { path: 'ecommerce', element: <GeneralEcommerce /> },
            // { path: 'analytics', element: <GeneralAnalytics /> },
            // { path: 'banking', element: <GeneralBanking /> },
            // { path: 'booking', element: <GeneralBooking /> },
            // produtos
            { path: 'products', element: <ProductList /> },
            { path: 'products/new', element: <ProductCreate /> },
            { path: 'products/:name/edit', element: <ProductEdit /> },
            { path: 'products/:name/sorteio-giro', element: <ProductEdit /> },
            // communications
            { path: 'communications', element: <CommunicationsList /> },
            { path: 'communications/:id/edit', element: <CommunicationsEdit /> },
            { path: 'communications/new', element: <CommunicationsCreate /> },
            // capitalizers
            { path: 'capitalizers', element: <CapitalizerList /> },
            // { path: 'capitalizers/:id/edit', element: <CapitalizerEdit /> },
            { path: 'capitalizers/new', element: <CapitalizerCreate /> },
            { path: 'capitalizers/:id/schedules', element: <CapitalizerScheduleList /> },
            { path: 'capitalizers/:id/schedules/new', element: <CapitalizerScheduleCreate /> },
            // settings
            { path: 'settings/faqs', element: <FAQList /> },
            { path: 'settings/faqs/new', element: <FAQCreate /> },
            { path: 'settings/faqs/:id/edit', element: <FAQEdit /> },
            { path: 'settings/social-medias', element: <SettingsSocialMedias /> },
            { path: 'settings/terms', element: <SettingsTerms /> },
            { path: 'settings/params', element: <SettingsParams /> },
            { path: 'settings/themes', element: <SettingsThemes /> },
            { path: 'settings/certificate', element: <SettingsCertificate /> },

            {
               path: 'e-commerce',
               children: [
                  // { element: <Navigate to="/dashboard/e-commerce/shop" replace />, index: true },
                  // { path: 'shop', element: <EcommerceShop /> },
                  { path: 'list', element: <EcommerceProductList /> },
                  { path: 'product/new', element: <EcommerceProductCreate /> },
                  { path: 'product/:name/edit', element: <EcommerceProductEdit /> },
                  // { path: 'checkout', element: <EcommerceCheckout /> },
               ],
            },
            {
               path: 'user',
               children: [
                  { element: <Navigate to="/dashboard/user/profile" replace />, index: true },
                  // { path: 'profile', element: <UserProfile /> },
                  { path: 'cards', element: <UserCards /> },
                  { path: 'list', element: <UserList /> },
                  { path: 'new', element: <UserCreate /> },
                  { path: ':id/edit', element: <UserCreate /> },
                  { path: ':id/bank-account', element: <UserBankAccount /> }
                  // { path: 'account', element: <UserAccount /> },
               ],
            },
            {
               path: 'invoice',
               children: [
                  { element: <Navigate to="/dashboard/invoice/list" replace />, index: true },
                  { path: ':id/orders', element: <InvoiceList /> },

                  { path: 'list', element: <InvoiceList /> },
                  { path: ':id', element: <InvoiceDetails /> },
                  // { path: ':id/edit', element: <InvoiceEdit /> },
                  // { path: 'new', element: <InvoiceCreate /> },
               ],
            },
            // {
            //    path: 'blog',
            //    children: [
            //       { element: <Navigate to="/dashboard/blog/posts" replace />, index: true },
            //       { path: 'posts', element: <BlogPosts /> },
            //       { path: 'post/:title', element: <BlogPost /> },
            //       { path: 'new', element: <BlogNewPost /> },
            //    ],
            // },
            // {
            //    path: 'mail',
            //    children: [
            //       { element: <Navigate to="/dashboard/mail/all" replace />, index: true },
            //       { path: 'label/:customLabel', element: <Mail /> },
            //       { path: 'label/:customLabel/:mailId', element: <Mail /> },
            //       { path: ':systemLabel', element: <Mail /> },
            //       { path: ':systemLabel/:mailId', element: <Mail /> },
            //    ],
            // },
            // {
            //    path: 'chat',
            //    children: [
            //       { element: <Chat />, index: true },
            //       { path: 'new', element: <Chat /> },
            //       { path: ':conversationKey', element: <Chat /> },
            //    ],
            // },
            // { path: 'calendar', element: <Calendar /> },
            // { path: 'kanban', element: <Kanban /> },
            { path: 'permission-denied', element: <PermissionDenied /> },
         ],
      },

      // Main Routes
      {
         path: '*',
         // element: <LogoOnlyLayout />,
         // element: <MainLayout />,
         children: [
            // { path: 'compra/:id', element: <Pricing /> },
            // { path: 'campanhas', element: <Campaigns /> },
            // { path: 'campanha/:name', element: <EcommerceProductDetails /> },
            // { path: 'termos-de-uso', element: <Terms /> },
            // { path: 'comunicados', element: <Communications /> },
            // { path: 'payment', element: <Payment /> },
            { path: 'coming-soon', element: <ComingSoon /> },
            { path: 'maintenance', element: <Maintenance /> },
            { path: '500', element: <Page500 /> },
            { path: '404', element: <Page404 /> },
            { path: '403', element: <Page403 /> },
            { path: '*', element: <Navigate to="/404" replace /> },
         ],
      },
      {
         path: '/',
         // element: <MainLayout />,
         element: (
            <GuestGuard>
               <Login />
            </GuestGuard>
         ),
         children: [
            { element: <HomePage />, index: true },
            // { path: 'about-us', element: <About /> },
            // { path: 'contato', element: <Contact /> },
            // { path: 'faqs', element: <Faqs /> },
         ],
      },
      { path: '*', element: <Navigate to="/404" replace /> },
   ]);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const NewPassword = Loadable(lazy(() => import('../pages/auth/NewPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));

// DASHBOARD

// GENERAL
const ProductList = Loadable(lazy(() => import('../pages/dashboard/ProductList')));
const ProductCreate = Loadable(lazy(() => import('../pages/dashboard/ProductCreate')));
const ProductEdit = Loadable(lazy(() => import('../pages/dashboard/ProductEdit')));

const FAQList = Loadable(lazy(() => import('../pages/dashboard/FAQList')));
const FAQCreate = Loadable(lazy(() => import('../pages/dashboard/FAQCreate')));
const FAQEdit = Loadable(lazy(() => import('../pages/dashboard/FAQEdit')));

const CommunicationsList = Loadable(lazy(() => import('../pages/dashboard/CommunicationsList')));
const CommunicationsEdit = Loadable(lazy(() => import('../pages/dashboard/CommunicationsEdit')));
const CommunicationsCreate = Loadable(lazy(() => import('../pages/dashboard/CommunicationsCreate')));

const CapitalizerList = Loadable(lazy(() => import('../pages/dashboard/CapitalizerList')));
const CapitalizerCreate = Loadable(lazy(() => import('../pages/dashboard/CapitalizerCreate')));
const CapitalizerScheduleList = Loadable(lazy(() => import('../pages/dashboard/CapitalizerScheduleList')));
const CapitalizerScheduleCreate = Loadable(lazy(() => import('../pages/dashboard/CapitalizerScheduleCreate')));


const SettingsThemes = Loadable(lazy(() => import('../pages/dashboard/SettingsTheme')));
const SettingsCertificate = Loadable(lazy(() => import('../pages/dashboard/SettingsCertificate')));
const SettingsParams = Loadable(lazy(() => import('../pages/dashboard/SettingsParams')));
const SettingsSocialMedias = Loadable(lazy(() => import('../pages/dashboard/SettingsSocialMediaEdit')));
const SettingsTerms = Loadable(lazy(() => import('../pages/dashboard/SettingsTerms')));

const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/GeneralApp')));
const GeneralEcommerce = Loadable(lazy(() => import('../pages/dashboard/GeneralEcommerce')));
const GeneralAnalytics = Loadable(lazy(() => import('../pages/dashboard/GeneralAnalytics')));
const GeneralBanking = Loadable(lazy(() => import('../pages/dashboard/GeneralBanking')));
// const GeneralBooking = Loadable(lazy(() => import('../pages/dashboard/GeneralBooking')));
const GeneralDashboard = Loadable(lazy(() => import('../pages/dashboard/GeneralDashboard')));

// ECOMMERCE
const EcommerceShop = Loadable(lazy(() => import('../pages/dashboard/EcommerceShop')));
const EcommerceProductDetails = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductDetails')));
const EcommerceProductList = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductList')));
const EcommerceProductCreate = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductCreate')));
const EcommerceProductEdit = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductEdit')));
const EcommerceCheckout = Loadable(lazy(() => import('../pages/dashboard/RifaCheckout')));
// const EcommerceCheckout = Loadable(lazy(() => import('../pages/dashboard/EcommerceCheckout')));

// INVOICE
const InvoiceList = Loadable(lazy(() => import('../pages/dashboard/InvoiceList')));
const InvoiceDetails = Loadable(lazy(() => import('../pages/dashboard/InvoiceDetails')));
const InvoiceCreate = Loadable(lazy(() => import('../pages/dashboard/InvoiceCreate')));
const InvoiceEdit = Loadable(lazy(() => import('../pages/dashboard/InvoiceEdit')));

// BLOG
const BlogPosts = Loadable(lazy(() => import('../pages/dashboard/BlogPosts')));
const BlogPost = Loadable(lazy(() => import('../pages/dashboard/BlogPost')));
const BlogNewPost = Loadable(lazy(() => import('../pages/dashboard/BlogNewPost')));

// USER
const UserProfile = Loadable(lazy(() => import('../pages/dashboard/UserProfile')));
const UserCards = Loadable(lazy(() => import('../pages/dashboard/UserCards')));
const UserList = Loadable(lazy(() => import('../pages/dashboard/UserList')));
const UserAccount = Loadable(lazy(() => import('../pages/dashboard/UserAccount')));
const UserBankAccount = Loadable(lazy(() => import('../pages/dashboard/UserBankAccount')));
const UserCreate = Loadable(lazy(() => import('../pages/dashboard/UserCreate')));

// APP
const Chat = Loadable(lazy(() => import('../pages/dashboard/Chat')));
const Mail = Loadable(lazy(() => import('../pages/dashboard/Mail')));
const Calendar = Loadable(lazy(() => import('../pages/dashboard/Calendar')));
const Kanban = Loadable(lazy(() => import('../pages/dashboard/Kanban')));

// TEST RENDER PAGE BY ROLE
const PermissionDenied = Loadable(lazy(() => import('../pages/dashboard/PermissionDenied')));

// MAIN
const HomePage = Loadable(lazy(() => import('../pages/Home')));
const About = Loadable(lazy(() => import('../pages/About')));
const Contact = Loadable(lazy(() => import('../pages/Contact')));
const Faqs = Loadable(lazy(() => import('../pages/Faqs')));
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));

const Pricing = Loadable(lazy(() => import('../pages/Pricing')));

const Payment = Loadable(lazy(() => import('../pages/Payment')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const Page403 = Loadable(lazy(() => import('../pages/Page403')));
const Page404 = Loadable(lazy(() => import('../pages/Page404')));

const Terms = Loadable(lazy(() => import('../pages/Terms')));
const Communications = Loadable(lazy(() => import('../pages/Communications')));
const Campaigns = Loadable(lazy(() => import('../pages/Campaigns')));
